<template>
  <div id="entryPoint">
    <b-progress v-show="showProgressBar" :value="progressValue" :max="progressMax"></b-progress>

    <!------------------------------------------------------------------------------------------------
      Title - this title will appear above the header
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointTitle" fluid="md" class="entrySection">
      <b-row><b-col><p class="entrySectionTitle">{{entrySectionTitle}}</p></b-col></b-row>
    </b-container>

    <!------------------------------------------------------------------------------------------------
      Header - This is not a section but rather is shown above other sections
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointHeader" fluid="md" v-show="showHeader" class="entrySection">
      <b-row><b-col>{{s('entryPoint.header.welcome')}} <strong>{{clientName}}</strong>.</b-col></b-row>
      <b-row><b-col>
        {{s('entryPoint.header.visitInfo_1')}} <strong>{{durationMinutes}} {{s('entryPoint.header.visitInfo_2')}}</strong> {{s('entryPoint.header.visitInfo_3')}}
        <strong>{{providerName}}</strong> {{s('entryPoint.header.visitInfo_4')}} <strong>{{clinicName}}</strong> {{s('entryPoint.header.visitInfo_5')}}
        <strong>{{visitDateStr}}</strong> {{s('entryPoint.header.visitInfo_6')}} <strong>{{visitTimeStr}}</strong>.
      </b-col></b-row>
      <!--Countdown :startTimeDate="startTimeDate"/-->
      <b-row><b-col>
        {{s('entryPoint.header.clinicInfo')}} <strong>{{clinicPhone}}</strong>.
      </b-col></b-row>
      <hr/>
    </b-container>

        <!------------------------------------------------------------------------------------------------
      ------ No Header ------
      Invalid Visit ID section
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointInvalidVisit" v-show="showInvalidVisit" fluid="md" class="entrySection">
      <b-row>
        <b-col>
          <p>{{s('entryPoint.invalidVisit.info')}}</p>
        </b-col>
      </b-row>
    </b-container>

    <!------------------------------------------------------------------------------------------------
      ------ No Header ------
      Browser Failed Validation Section
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointUnsupportedBrowser" v-show="showUnsupportedBrowser" fluid="md" class="entrySection">
      <b-row>
        <b-col>
          <p>{{s('entryPoint.unsupportedBrowser.info.top')}}</p>
          <ul>
            <li>{{s('entryPoint.unsupportedBrowser.info.li1')}}</li>
            <li>{{s('entryPoint.unsupportedBrowser.info.li2')}}</li>
            <li>{{s('entryPoint.unsupportedBrowser.info.li3')}}</li>
            <li>{{s('entryPoint.unsupportedBrowser.info.li4')}}</li>
            <li>{{s('entryPoint.unsupportedBrowser.info.li5')}}</li>
            <li>{{s('entryPoint.unsupportedBrowser.info.li6')}}</li>
            <li>{{s('entryPoint.unsupportedBrowser.info.li7')}}</li>
          </ul>
        </b-col>
      </b-row>
    </b-container>

    <!------------------------------------------------------------------------------------------------
      ------ No Header ------
      Past Visit Section
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointPastVisit" v-show="showPastVisit" fluid="md" class="entrySection">
      <b-row><b-col>{{s('entryPoint.pastVisit.msg')}}</b-col></b-row>
    </b-container>

    <!------------------------------------------------------------------------------------------------
      Too early for an appointment section
      also has become the "confirm your visit" section, dual purpose now
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointTooEarlyAndConfirm" v-show="showTooEarlyAndConfirm" fluid="md" class="entrySection">
      <b-row v-show="!visitConfirmed()">
        <b-col>
          {{s('entryPoint.confirmVisit.msg.1_1')}}
          <i>{{s('entryPoint.confirmVisit.confirmVisit')}}</i>
          {{s('entryPoint.confirmVisit.msg.1_2')}}
        </b-col></b-row>
      <b-row v-show="visitConfirmed()"><b-col>{{s('entryPoint.confirmVisit.msg.2')}}</b-col></b-row>
      <b-row class="buttonRow">
        <b-col cols="12" md="4" lg="3">
          <b-button v-show="!visitConfirmed()" variant="primary" v-on:click="confirmVisit()">{{s('entryPoint.confirmVisit.confirmVisit')}}</b-button>
        </b-col>
      </b-row>
      <b-row v-show="isTooEarlyForVisit()"><b-col>{{s('entryPoint.tooEarly.msg.1')}}</b-col></b-row>
      <b-row v-show="isTooEarlyForVisit()" style="margin-top: 0.75em;"><b-col>
        {{s('entryPoint.tooEarly.msg.2_1')}}<i>{{s('entryPoint.tooEarly.testStartButton')}}</i>{{s('entryPoint.tooEarly.msg.2_2')}}
      </b-col></b-row>
      <b-row v-show="isTooEarlyForVisit()" class="buttonRow">
        <b-col cols="12" md="6" lg="4" xl="3">
          <b-button variant="primary" v-on:click="tooEarlySpeedTest">{{s('entryPoint.tooEarly.testStartButton')}}</b-button>
        </b-col>
        <!--b-col cols="12" md="6" lg="4" xl="3">
          <b-button variant="primary" v-on:click="tooEarlyVisitTest">{{s('entryPoint.tooEarly.equipmentTestStartButton')}}</b-button>
        </b-col-->
      </b-row>
      <div v-if="hasMobileApp">
        <b-row>
          <b-col>
            <span>{{s('entryPoint.tooEarlyMobile.msg_1')}}</span>
            <i v-if="hasMobileAppAndroid">{{s('entryPoint.connectMobile.downloadAndroid')}}</i>
            <i v-if="hasMobileAppApple">{{s('entryPoint.connectMobile.downloadApple')}}</i>
            <span>{{s('entryPoint.tooEarlyMobile.msg_2')}}</span>
          </b-col>
        </b-row>
        <b-row class="buttonRow">
          <b-col v-if="hasMobileAppAndroid" cols="12" md="6" lg="4" xl="3">
            <a class="btn btn-secondary" :href="this.androidUrl" target="_blank" role="button">
              {{s('entryPoint.connectMobile.downloadAndroid')}}
            </a>
            <!--b-button variant="secondary" v-on:click="openAndroidStore">
              {{s('entryPoint.connectMobile.downloadAndroid')}}
            </b-button-->
          </b-col>
          <b-col v-if="hasMobileAppApple" cols="12" md="6" lg="4" xl="3">
            <a class="btn btn-secondary" :href="this.appleUrl" target="_blank" role="button">
              {{s('entryPoint.connectMobile.downloadApple')}}
            </a>
            <!--b-button variant="secondary" v-on:click="openAppleStore">
              {{s('entryPoint.connectMobile.downloadApple')}}
            </b-button-->
          </b-col>
        </b-row>
      </div>
    </b-container>

    <!------------------------------------------------------------------------------------------------
      Speed Test Section
    ------------------------------------------------------------------------------------------------->
    <b-container id="speedTest" fluid="md" v-show="showSpeedTest" class="entrySection">
      <b-row>
        <b-col>
          <span>{{s('entryPoint.speedTest.steps.intro1')}}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ol id="speedTestSteps">
            <li>{{s('entryPoint.speedTest.steps.li1_1')}}<i>{{speedTestStartButtonName}}</i>{{s('entryPoint.speedTest.steps.li1_2')}}</li>
            <li>{{s('entryPoint.speedTest.steps.li2')}}</li>
            <li>{{s('entryPoint.speedTest.steps.li3')}}<i>{{s('entryPoint.speedTest.skipTestButton')}}</i>.</li>
          </ol>
        </b-col>
      </b-row>
      <b-row id="speedTestButtons" class="buttonRow">
        <b-col cols="12" md="6" lg="3" v-if="!speedTestRunning">
          <b-button variant="primary"  v-on:click="startSpeedTest()">{{speedTestStartButtonName}}</b-button>
        </b-col>
        <b-col cols="12" md="6" lg="3" v-if="speedTestRunning">
          <b-button variant="warning"  v-on:click="stopSpeedTest()">{{s('entryPoint.speedTest.stopSpeedTestButton')}}</b-button>
        </b-col>
        <!--b-col cols="12" md="6" lg="3" v-if="(speedTestComplete || speedTestStopped) && showBackToTestOptions && !speedTestRunning">
          <b-button variant="primary"  v-on:click="returnToTestOptions()">{{s('entryPoint.speedTest.returnToTestOptionsButton')}}</b-button>
        </b-col-->
        <b-col cols="12" md="6" lg="3" v-if="!speedTestNextStep" >
          <b-button variant="primary" v-on:click="skipSpeedTest()">{{s('entryPoint.speedTest.skipTestButton')}}</b-button>
        </b-col>
        <b-col cols="12" md="6" lg="3" v-if="speedTestNextStep" >
          <b-button variant="primary" v-on:click="speedTestStartNextStep()">{{s('entryPoint.speedTest.nextStepButton')}}</b-button>
        </b-col>
        
      </b-row>
      <b-row id="speedTestResult" class="testResult" v-if="speedTestComplete">
        <b-col v-if="speedTestFailed">
          <table>
            <tr>
              <td class="testResultIcon">
                <font-awesome-icon class="failIcon" :icon="['fa', 'times-circle']" size="2x" />
              </td>
              <td class="testResultDescription">
                <div>
                  <span>
                    {{s('entryPoint.speedTest.complete.failed_1')}}
                    <i>{{s('entryPoint.speedTest.restartSpeedTestButton')}}</i>
                    {{s('entryPoint.speedTest.complete.failed_2')}}
                    <strong>{{this.clinicPhone}}</strong>
                    {{s('entryPoint.speedTest.complete.failed_3')}}
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </b-col>
        <!--b-col v-if="speedTestPassed">
          <table>
            <tr>
              <td class="testResultIcon">
                <font-awesome-icon class="passIcon" :icon="['fas', 'check-circle']" size="2x" />
              </td>
              <td class="testResultDescription">
                <div>
                  <span>{{s('entryPoint.speedTest.complete.passed1')}}</span>
                </div>
                <div>
                  <span>{{s('entryPoint.speedTest.complete.passed2_1')}}</span>
                  <span style="font-weight:bold;">{{this.suggestedEarlyForVisitMinutes}}{{s('entryPoint.speedTest.complete.passed2_2')}}</span>
                  <span>{{s('entryPoint.speedTest.complete.passed2_3')}}</span>
                </div>
              </td>
            </tr>
          </table>
        </b-col-->
        <b-col v-if="speedTestNextStep">
          <table>
            <tr>
              <td class="testResultIcon">
                <font-awesome-icon  class="passIcon" :icon="['fas', 'check-circle']" size="2x" />
              </td>
              <td class="testResultDescription">
                <span>{{s('entryPoint.speedTest.complete.next_step_1')}}<i>{{s('entryPoint.speedTest.nextStepButton')}}</i>{{s('entryPoint.speedTest.complete.next_step_2')}}</span>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row id="speedTestRow">
        <b-col>
          <iframe v-if="showSpeedTestElement" id="speedTestPage" width="100%" height="620px" frameborder="0" src="https://sharedhealthmb.speedtestcustom.com"></iframe>
        </b-col>
      </b-row>
    </b-container>

    <!--b-container id="entryPointSpeedTest" v-show="showSpeedTest" fluid="md" class="entrySection">
      <b-row>
        <b-col><span class="entrySectionTitle">{{s('entryPoint.speedTest.title')}}</span></b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="mockup">{{s('entryPoint.speedTest.mockup.1')}}</h3>
          <p class="mockup">{{s('entryPoint.speedTest.mockup.2')}}</p>
          <p class="mockup">{{s('entryPoint.speedTest.mockup.3')}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>{{s('entryPoint.speedTest.info.top')}}</p>
          <ol>
            <li>{{s('entryPoint.speedTest.info.li1_1')}} <i>{{s('entryPoint.speedTest.info.li1.testButtonName')}}</i> {{s('entryPoint.speedTest.info.li1_2')}}</li>
            <li>
              {{s('entryPoint.speedTest.info.li2')}}
              <ul>
                <li>{{s('entryPoint.speedTest.info.li2.1')}}</li>
                <li>{{s('entryPoint.speedTest.info.li2.2')}}</li>
              </ul>
            </li>
            <li>{{s('entryPoint.speedTest.info.li3_1')}} <i>{{s('entryPoint.speedTest.fastEnoughButton')}}</i> {{s('entryPoint.speedTest.info.li3_2')}}</li>
          </ol>
          <b-button variant="primary" v-on:click="internetIsFastEnough()">{{s('entryPoint.speedTest.fastEnoughButton')}}</b-button>
        </b-col>
        <b-col>
          <b-img src="/wp-content/uploads/speedTestMock.png" />
        </b-col>
      </b-row>
    </b-container-->

    <!------------------------------------------------------------------------------------------------
      Virtual Appointment (equipment) Test Section
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointVisitTest" v-show="showVisitTest" fluid="md" class="entrySection">
      <b-row><b-col>
          {{s('entryPoint.visitTest.info.top_2')}}
          <ol>
            <li>{{s('entryPoint.visitTest.info.li1_1')}} <i>{{visitTestStartButtonName}}</i>{{s('entryPoint.visitTest.info.li1_2')}}</li>
            <li>{{s('entryPoint.visitTest.info.li2')}}</li>
            <li>
              {{s('entryPoint.visitTest.info.li3_1')}} <i>{{s('entryPoint.visitTest.testPassButton')}}</i> {{s('entryPoint.visitTest.info.li3_2')}}
              <i>{{s('entryPoint.visitTest.testFailButton')}}</i>{{s('entryPoint.visitTest.info.li3_3')}}
            </li>
          </ol>
      </b-col></b-row>
      <b-row class="buttonRow" id="equipmentTestButtonRow">
        <b-col cols="12" :md="testCompleteButtonMdSize" lg="3" v-if="pexLoaded && !equipmentTestOngoing && !testLoading">
          <b-button variant="primary" v-on:click="visitTestStart()" >{{visitTestStartButtonName}}</b-button>
        </b-col>
        <b-col cols="12" md="6" lg="3" v-if="equipmentTestOngoing || testLoading">
          <b-button variant="warning" v-on:click="pexipTestStop()" >{{s('entryPoint.visitTest.stopTestButton')}}</b-button>
        </b-col>
        <!--b-col cols="12" :md="testCompleteButtonMdSize" lg="3" v-if="showVisitTestCompleteButton && showBackToTestOptions">
          <b-button variant="primary"  v-on:click="returnToTestOptions()">{{s('entryPoint.visitTest.returnToTestOptionsButton')}}</b-button>
        </b-col-->
        <b-col cols="6" :md="testCompleteButtonMdSize" lg="3" v-if="showVisitTestCompleteButton" >
          <b-button variant="success" v-on:click="visitTestComplete()">{{s('entryPoint.visitTest.testPassButton')}}</b-button>
        </b-col>
        <b-col cols="6" :md="testCompleteButtonMdSize" lg="3" v-if="showVisitTestCompleteButton">
          <b-button variant="danger"  v-on:click="visitTestFail()">{{s('entryPoint.visitTest.testFailButton')}}</b-button>
        </b-col>
      </b-row>

      <b-row v-show="showVisitTestFailInfo || showVisitTestRtcFailInfo || showVisitTestEquipmentFailInfo" class="testResult"><b-col>
        <table>
            <tr>
              <td class="testResultIcon">
                <font-awesome-icon class="failIcon" :icon="['fa', 'times-circle']" size="2x" />
              </td>
              <td class="testResultDescription">
                <div v-if="showVisitTestFailInfo">
                  {{s('entryPoint.visitTest.info.fail_1')}}
                  <i>{{s('entryPoint.visitTest.testStartButton.retest')}}</i>
                  {{s('entryPoint.visitTest.info.fail_2')}}
                  <strong>{{this.clinicPhone}}</strong>
                  {{s('entryPoint.visitTest.info.fail_3')}}
                </div>
                <div v-if="showVisitTestEquipmentFailInfo">
                  {{s('entryPoint.visitTest.info.equipment_fail')}}
                </div>
                <div v-if="showVisitTestRtcFailInfo">
                  {{s('entryPoint.visitTest.info.rtc_fail')}}
                </div>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row v-show="showVisitTestSuccessInfo" class="testResult">
        <b-col>
          <table>
            <tr>
              <td class="testResultIcon">
                <font-awesome-icon  class="passIcon" :icon="['fas', 'check-circle']" size="2x" />
              </td>
              <td class="testResultDescription">
                {{s('entryPoint.visitTest.info.success')}}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row id="loadingSpinner" v-if="testLoading">
        <b-col class="text-center">
          <b-spinner variant="primary" style="width: 4em; height: 4em;"></b-spinner>
        </b-col>
      </b-row>
      <div id="textPexipVideoSelfPreviewContainer" v-show="equipmentTestOngoing && showPreview">
        <video autoplay playsinline  width="100%" muted id="textPexipVideoSelfPreview"/>
      </div>
      <div id="testPexipVideoStreamContainer" v-show="!audioOnlyCall && equipmentTestOngoing">
        <video autoplay playsinline @play="testStreamStarted" @loadedmetadata="testStreamStarted" width="100%" id="testPexipVideoStream"/>
      </div>
      <div id="audioSpinnerContainer" v-show="audioOnlyCall && equipmentTestOngoing">
        <b-col class="text-center">
          <div>{{s('entryPoint.visitTest.info.audioTestDescription1')}}</div>
          <div>{{s('entryPoint.visitTest.info.audioTestDescription2')}}</div>
          <b-spinner variant="primary" type="grow" style="width: 6em; height: 6em;"></b-spinner>
        </b-col>
      </div>
    </b-container>

    <!------------------------------------------------------------------------------------------------
      Connect To Your Appointment Section
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointConnect" v-show="showConnect" fluid="md" class="entrySection">
      <div v-if="!hasMobileApp">
        <b-row>
          <b-col>
            <ol>
              <li>{{s('entryPoint.connect.msg_1_1')}} <i>{{s('entryPoint.connect.beginButton')}}</i>{{s('entryPoint.connect.msg_1_2')}}</li>
              <li>{{s('entryPoint.connect.msg_2')}}</li>
            </ol>
          </b-col>
        </b-row>
        <b-row class="buttonRow">
          <b-col cols="12" md="6" lg="4" xl="3">
            <!--b-button :title="loadingPexipTitle" :disabled="loadingPexip" variant="primary" v-on:click="beginVisit()">
              {{s('entryPoint.connect.beginButton')}}
            </b-button-->
            <a :class="pexipButtonClass" :href="this.browserUrl" target="_blank" role="button" v-on:click="transitionToReconnect" :title="loadingPexipTitle" :aria-disabled="loadingPexip" >
              {{s('entryPoint.connect.beginButton')}}
            </a>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row style="margin-bottom: 1em;">
          <b-col>
            <span>{{s('entryPoint.connectMobile.msg')}}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b>{{s('entryPoint.connect.browser')}}</b>
            <ol>
              <li>{{s('entryPoint.connect.browser.step_1_1')}}<i>{{s('entryPoint.connect.beginButtonBrowser')}}</i>{{s('entryPoint.connect.browser.step_1_2')}}</li>
              <li>{{s('entryPoint.connect.browser.step_2')}}</li>
              <li>{{s('entryPoint.connect.browser.step_3')}}</li>
            </ol>
          </b-col>
        </b-row>
        <b-row class="buttonRow">
          <b-col cols="12" md="6" lg="4" xl="3">
            <!--b-button :title="loadingPexipTitle" :disabled="loadingPexip" variant="primary" v-on:click="beginVisit()">
              {{s('entryPoint.connect.beginButtonBrowser')}}
            </b-button-->
            <a :class="pexipButtonClass" :href="this.browserUrl" target="_blank" role="button" v-on:click="transitionToReconnect" :title="loadingPexipTitle" :aria-disabled="loadingPexip" >
              {{s('entryPoint.connect.beginButtonBrowser')}}
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b>{{s('entryPoint.connect.app')}}</b>
            <ol>
              <li>
                {{s('entryPoint.connect.app.step_1_1')}}<i v-if="hasMobileAppAndroid">{{s('entryPoint.connectMobile.downloadAndroid')}}</i><i v-if="hasMobileAppApple">{{s('entryPoint.connectMobile.downloadApple')}}</i>
                {{s('entryPoint.connect.app.step_1_2')}}
              </li>
              <li>
                {{s('entryPoint.connect.app.step_2_1')}}<i>{{s('entryPoint.connectMobile.begin')}}</i>{{s('entryPoint.connect.app.step_2_2')}}
              </li>
              <li>{{s('entryPoint.connect.app.step_3')}}</li>
            </ol>
          </b-col>
        </b-row>
        <b-row class="buttonRow">
          <b-col v-if="hasMobileAppAndroid" cols="12" md="6" lg="4" xl="3">
            <a class="btn btn-secondary" :href="this.androidUrl" target="_blank" role="button">
              {{s('entryPoint.connectMobile.downloadAndroid')}}
            </a>
            <!--b-button variant="secondary" v-on:click="openAndroidStore">
              {{s('entryPoint.connectMobile.downloadAndroid')}}
            </b-button-->
          </b-col>
          <b-col v-if="hasMobileAppApple" cols="12" md="6" lg="4" xl="3">
            <a class="btn btn-secondary" :href="this.appleUrl" target="_blank" role="button">
              {{s('entryPoint.connectMobile.downloadApple')}}
            </a>
            <!--b-button variant="secondary" v-on:click="openAppleStore">
              {{s('entryPoint.connectMobile.downloadApple')}}
            </b-button-->
          </b-col>
          <b-col cols="12" md="6" lg="4" xl="3">
            <!--b-button :title="loadingPexipTitle" :disabled="loadingPexip" variant="primary" v-on:click="beginVisit(true)">
              {{s('entryPoint.connectMobile.begin')}}
            </b-button-->
            <a :class="pexipButtonClass" :href="this.appUrl" target="_blank" role="button" v-on:click="transitionToReconnect" :title="loadingPexipTitle" :aria-disabled="loadingPexip" >
              {{s('entryPoint.connectMobile.begin')}}
            </a>
          </b-col>
        </b-row>
      </div>

      <b-row v-if="loadingPexip" class="pexipLoadingRow">
        <b-col class="text-center">
          <b-spinner variant="primary" style="width: 4em; height: 4em;"></b-spinner>
          <div class="pexipLoadingMessage">{{s('entryPoint.connect.loadingPexip')}}</div>
        </b-col>
      </b-row>
    </b-container>

    <!------------------------------------------------------------------------------------------------
      Reconnect Section
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointReconnect" v-show="showReconnect" fluid="md" class="entrySection">
      <b-row>
        <b-col v-if="!hasMobileApp">
          {{s('entryPoint.reconnect.msg_11')}} <i>{{s('entryPoint.reconnect.reconnectButton')}}</i>{{s('entryPoint.reconnect.msg_12')}}
        </b-col>
        <b-col v-else>
          {{s('entryPoint.reconnectApp.msg_11')}} <i>{{s('entryPoint.reconnect.reconnectBrowserButton')}}</i>{{s('entryPoint.reconnectApp.msg_12')}}<i>{{s('entryPoint.reconnect.reconnectAppButton')}}</i>{{s('entryPoint.reconnectApp.msg_21')}}
        </b-col>
      </b-row>
      <b-row style="margin-top:0.5em;">
        <b-col>
          {{s('entryPoint.reconnect.msg_21')}} <i>{{s('entryPoint.reconnect.endButton')}}</i>{{s('entryPoint.reconnect.msg_22')}}
        </b-col>
      </b-row>
      <b-row class="buttonRow">
        <b-col cols="12" md="6" lg="4" xl="3">
          <a :class="pexipButtonClass" :href="this.browserUrl" target="_blank" role="button" :title="loadingPexipTitle" :aria-disabled="loadingPexip" >
            {{s(hasMobileApp?'entryPoint.reconnect.reconnectBrowserButton':'entryPoint.reconnect.reconnectButton')}}
          </a>
          <!--b-button :title="loadingPexipTitle" :disabled="loadingPexip" variant="primary" v-on:click="beginVisit()">
            {{s(hasMobileApp?'entryPoint.reconnect.reconnectButton':'entryPoint.reconnect.reconnectBrowserButton')}}
          </b-button-->
        </b-col>
        <b-col v-if="hasMobileApp" cols="12" md="6" lg="4" xl="3">
          <a :class="pexipButtonClass" :href="this.appUrl" target="_blank" role="button" :title="loadingPexipTitle" :aria-disabled="loadingPexip" >
            {{s('entryPoint.reconnect.reconnectAppButton')}}
          </a>
          <!--b-button :title="loadingPexipTitle" :disabled="loadingPexip" variant="primary" v-on:click="beginVisit(true)">
            {{s('entryPoint.reconnect.reconnectAppButton')}}
          </b-button-->
        </b-col>
        <b-col cols="12" md="6" lg="4" xl="3">
          <b-button variant="primary" v-on:click="endVisit()">
            {{s('entryPoint.reconnect.endButton')}}
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="loadingPexip" class="pexipLoadingRow">
        <b-col class="text-center">
          <b-spinner variant="primary" style="width: 4em; height: 4em;"></b-spinner>
          <div class="pexipLoadingMessage">{{s('entryPoint.connect.loadingPexip')}}</div>
        </b-col>
      </b-row>
    </b-container>

    <!------------------------------------------------------------------------------------------------
      Visit Complete Section
    ------------------------------------------------------------------------------------------------->
    <b-container id="entryPointComplete" v-show="showComplete" fluid="md" class="entrySection">
      <b-row>
        <b-col>
          <span>{{s('entryPoint.complete.msg_1')}}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span>{{s('entryPoint.complete.msg_2')}}<strong>{{clinicPhone}}</strong>.</span>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import date from 'date-and-time'
// import compareVersions from 'compare-versions'
import { swaggerRequest } from '../appDevSharedUtils'
import detectrtc from 'detectrtc'
import Countdown from '@/components/Countdown.vue'
const millisPerMinute = 60000

/** The section names used for showing/hiding. 
*/
const sectionNames = {
  invalidVisit: 'invalidVisit',
  unsupportedBrowser: 'unsupportedBrowser',
  pastVisit: 'pastVisit',
  tooEarly: 'tooEarly',
  speedTest: 'speedTest',
  visitTest: 'visitTest',
  connect: 'connect',
  reconnect: 'reconnect',
  complete: 'complete'
}

export default {
  data () {
    // i want to only define this set of fields once
    const initialValues = {
      // --------- Progress Bar ---------
      progressMax: 6,
      progressValue: 1,
      showProgressBar: false,

      // --------- title is used in all sections ---------
      entrySectionTitle: this.s('title.entryPoint'),

      // --------- Header ---------
      showHeader: false,
      visitDateStr: '',
      visitTimeStr: '',
      showVisitCountdown: false,
      timeCountdown: '',
      countdownInterval: '',

      // --------- Sections ---------
      showInvalidVisit: false,
      showUnsupportedBrowser: false,
      showPastVisit: false,
      showTooEarlyAndConfirm: false,
      showSpeedTest: false,
      showVisitTest: false,
      showConnect: false,
      showReconnect: false,
      showComplete: false,

      // --------- Visit Info From Backend ---------
      isVisitInPast: false,
      status: '',
      statusFr: '',
      providerName: '',
      clientName: '',
      clinicName: '',
      clinicEmail: '',
      clinicPhone: '',
      startTime: '',
      durationMinutes: 0,
      pexipUrl: '',
      isConfirmed: false,
      tooEarlyForVisitMinutes: 0,
      suggestedEarlyForVisitMinutes: 0,

      // ---------a value we are cmputing based off of back end data -------
      startTimeDate: null,

      // --------- Virtual Appointment Test Section ---------
      visitTestStartButtonName: '',
      showVisitTestCompleteButton: false,
      showVisitTestFailInfo: false,
      showVisitTestEquipmentFailInfo: false,
      showVisitTestRtcFailInfo: false,
      showVisitTestSuccessInfo: false,

      localMediaStream: null,
      showBackToTestOptions: false,
      testLoading: false,
      equipmentTestOngoing: false,
      showPreview: false,
      audioOnlyCall: false,

      pexLoadChecker: null,
      pexLoaded: false,
      pexRtc: null,

      // --------- speed test section ---------
      speedTestStartButtonName: '',
      showSpeedTestElement: false,
      speedTestComplete: false,
      speedTestRunning: false,
      speedTestFailed: false,
      speedTestLoaded: false,
      speedTestNextStep: false,
      speedTestStopped: false,

      // for room creation spinner
      loadingPexip: false,

      appUrl: null,
      browserUrl: null,

      appleUrl: 'https://apps.apple.com/ca/app/pexip-infinity-connect/id1195088102',
      androidUrl: 'https://play.google.com/store/apps/details?id=com.pexip.infinityconnect',
    }
    // so i keep the original set here
    const data = {
      initialValues: initialValues
    }
    // and copy the fields into the data object here
    for (const key in initialValues) {
      data[key] = initialValues[key]
    }
    // this way I can reset the view easily
    return {
      initialValues: initialValues,
      ...data
    }
  },
  components: {
    Countdown
  },
  methods: {
    /**
     * Switch what section of the screen is being shown.  Also update the progress bar accordingly.
     */
    showSection (sectionName) {
      //todo set confirm as the to early, maybe create same just for that
      this.currSection = sectionName
      switch (sectionName) {
        case sectionNames.invalidVisit:
          this.showProgressBar = false
          this.entrySectionTitle = this.s('entryPoint.invalidVisit.title')
          this.showHeader = false

          this.showInvalidVisit = true
          this.showUnsupportedBrowser = false
          this.showPastVisit = false
          this.showTooEarlyAndConfirm = false
          this.showSpeedTest = false
          this.showVisitTest = false
          this.showConnect = false
          this.showReconnect = false
          this.showComplete = false
          break
        case sectionNames.unsupportedBrowser:
          this.showProgressBar = false
          this.entrySectionTitle = this.s('entryPoint.unsupportedBrowser.title')
          this.showHeader = false

          this.showInvalidVisit = false
          this.showUnsupportedBrowser = true
          this.showPastVisit = false
          this.showTooEarlyAndConfirm = false
          this.showSpeedTest = false
          this.showVisitTest = false
          this.showConnect = false
          this.showReconnect = false
          this.showComplete = false
          break
        case sectionNames.pastVisit:
          this.showProgressBar = false
          this.entrySectionTitle = this.s('entryPoint.pastVisit.title')
          this.showHeader = false

          this.showInvalidVisit = false
          this.showUnsupportedBrowser = false
          this.showPastVisit = true
          this.showTooEarlyAndConfirm = false
          this.showSpeedTest = false
          this.showVisitTest = false
          this.showConnect = false
          this.showReconnect = false
          this.showComplete = false
          break
        case sectionNames.tooEarly:
          this.showProgressBar = false
          this.entrySectionTitle = !this.visitConfirmed() ? this.s('entryPoint.confirmVisit.title') : this.s('entryPoint.tooEarly.title')
          this.showHeader = true

          this.showInvalidVisit = false
          this.showUnsupportedBrowser = false
          this.showPastVisit = false
          this.showTooEarlyAndConfirm = true
          this.showSpeedTest = false
          this.showVisitTest = false
          this.showConnect = false
          this.showReconnect = false
          this.showComplete = false
          break
        case sectionNames.speedTest:
          this.showProgressBar = !this.isTooEarlyForVisit()
          this.progressValue = 1
          this.entrySectionTitle = this.s('entryPoint.speedTest.title')
          this.showHeader = true

          this.speedTestStartButtonName = this.s('entryPoint.speedTest.startSpeedTest')
          this.speedTestComplete = false
          this.showInvalidVisit = false
          this.showUnsupportedBrowser = false
          this.showPastVisit = false
          this.showTooEarlyAndConfirm = false
          this.speedTestRunning = false
          this.speedTestFailed = false
          this.speedTestNextStep = false
          this.showSpeedTestElement = false
          this.speedTestStopped = false
          this.showSpeedTest = true
          this.showVisitTest = false
          this.showConnect = false
          this.showReconnect = false
          this.showComplete = false
          // only need this until we have the callback from the completed test hooked up
          //this.speedTestComplete({origin:'https://sharedhealthmb.speedtestcustom.com',data:null})
          break
        case sectionNames.visitTest:
          this.showProgressBar = !this.isTooEarlyForVisit()
          this.progressValue = 2
          this.entrySectionTitle = this.s('entryPoint.visitTest.title')
          this.showHeader = true
          this.visitTestStartButtonName = this.s('entryPoint.visitTest.testStartButton')

          this.showInvalidVisit = false
          this.showUnsupportedBrowser = false
          this.showPastVisit = false
          this.showTooEarlyAndConfirm = false
          this.showSpeedTest = false
          this.showVisitTest = true
          this.showConnect = false
          this.showReconnect = false
          this.showComplete = false
          break
        case sectionNames.connect:
          this.showProgressBar = true
          if(this.progressValue<4){
            this.progressValue = 4
          }
          this.entrySectionTitle = this.s('entryPoint.connect.title')
          this.showHeader = true

          this.showInvalidVisit = false
          this.showUnsupportedBrowser = false
          this.showPastVisit = false
          this.showTooEarlyAndConfirm = false
          this.showSpeedTest = false
          this.showVisitTest = false
          this.showConnect = true
          this.showReconnect = false
          this.showComplete = false
          this.getPexipLinks()
          break
        case sectionNames.reconnect:
          this.showProgressBar = true
          this.progressValue = 5
          this.entrySectionTitle = this.s('entryPoint.reconnect.title')
          this.showHeader = true

          this.showInvalidVisit = false
          this.showUnsupportedBrowser = false
          this.showPastVisit = false
          this.showTooEarlyAndConfirm = false
          this.showSpeedTest = false
          this.showVisitTest = false
          this.showConnect = false
          this.showReconnect = true
          this.showComplete = false
          break
        case sectionNames.complete:
          this.showProgressBar = true
          this.progressValue = 6
          this.entrySectionTitle = this.s('entryPoint.complete.title')
          this.showHeader = false

          this.showInvalidVisit = false
          this.showUnsupportedBrowser = false
          this.showPastVisit = false
          this.showTooEarlyAndConfirm = false
          this.showSpeedTest = false
          this.showVisitTest = false
          this.showConnect = false
          this.showReconnect = false
          this.showComplete = true
          break
        default:
          console.error(`showSection: Unknown section name of: ${sectionName}`)
      }
      return this.currSection === sectionName
    },

    /*startVisitTimeReached() {
      if(this.currSection === sectionNames.tooEarly){
        this.showSection(sectionNames.tooEarly)
      }
    },*/
    /**
     * returns true if visit is confirmed
     */
    visitConfirmed () {
      return this.isConfirmed
    },

    confirmVisit() {
      swaggerRequest('confirmStatus', { visitId: this.$route.params.visitId,  }, (response) => {
          if (typeof response === 'object' && response.error) {
            // show something about not being set
          } else {
            if (response.emailSuccess) {
              this.showEmailSuccessToast()
            } else {
              this.showEmailErrorToast()
            }
            this.isConfirmed = response.isConfirmed
            if(!this.isTooEarlyForVisit()){
              this.showSection(sectionNames.speedTest)
            }
          }
        })
    },
    showEmailSuccessToast: function () {
      this.$bvToast.toast(this.s('entryPoint.toast.email.success.body'), {
        title: this.s('entryPoint.toast.view.email.success.title'),
        autoHideDelay: 5000,
        variant: 'success'
      })
    },
    showEmailErrorToast: function () {
      this.$bvToast.toast(this.s('entryPoint.toast.email.fail.body'), {
        title: this.s('entryPoint.toast.email.fail.title'),
        autoHideDelay: 5000,
        variant: 'danger'
      })
    },
    /**
     * Check of the browser is suported by pexip
     * https://docs.pexip.com/clients/using_web_admin.htm
     */
    isBrowserSupported () {
      return detectrtc.isWebRTCSupported
    },
    /**
     * The internet is fast enough button was clicked, carry on to the next section.
     */
    internetIsFastEnough () {
      this.showSection(sectionNames.visitTest)
    },
    /**
     * returns true if it is too early to start the visit workflow.
     */
    isTooEarlyForVisit () {
      if(this.startTimeDate && this.tooEarlyForVisitMinutes) {
        return Date.now() < this.startTimeDate.getTime() - this.tooEarlyForVisitMinutes * millisPerMinute
      } else {
        return false
      }
    },
    /**
     * change to the test page from the visit too early page.
     */
    tooEarlyVisitTest () {
      this.showSection(sectionNames.visitTest)
    },

    tooEarlySpeedTest () {
      this.showSection(sectionNames.speedTest)
    },
    /**
     * Open a tab and start the virtual appointment test.
     */
    visitTestStart () {
      this.pexipTestStart()
      if (!this.isTooEarlyForVisit() && this.progressValue < 3) {
        this.progressValue = 3
      }
    },

    // pexipTestOn* functions are all callback handlers set in the Pexip library

    // we need to react to onSetup to initiate the call with the connect method
    // also where we would set the pin, and other stuff
    // we can also  use this stream to echo the user to themself
    pexipTestOnSetup (stream, pin_status, conference_extension) {
      this.localMediaStream = stream
      this.pexRtc.connect()
    },

    // our chance to react to errors: display them to the user, and end the test
    pexipTestOnError (error) {
      /*console.log("ON ERROR")
      console.log(error)*/
      this.pexipHandleError(error)

    },

    // once its connected, we attach the stream to the video object, and wait for it to start, when it does, the callback below is triggered by the DOM
    pexipTestOnConnect (stream) {
      document.getElementById("testPexipVideoStream").srcObject = stream
    },

    // a callback for when the test stream DOM element starts playing - scroll to bottom
    testStreamStarted () {
      if(!this.equipmentTestOngoing) {
        this.startPreview()
        this.testLoading=false
        this.equipmentTestOngoing=true
        if(!this.audioOnlyCall) {
          this.setEquipmentTestScroll()
        }
      }
    },

    /// only called in the preceeding method
    setEquipmentTestScroll () {
      this.$nextTick(()=>{
        let videoElement = document.getElementById("testPexipVideoStreamContainer")
        let buttonRow = document.getElementById("equipmentTestButtonRow")
        // if the viewport isn't big enough for everything, move the video to the top of the screen
        if(buttonRow.getBoundingClientRect().height + videoElement.getBoundingClientRect().height > window.innerHeight) {
          window.scrollTo(0, videoElement.offsetTop)
        // if the viewport is big enough for everything and
        // if we're too low, move the buttons to the top of the screen
        } else  if(buttonRow.offsetTop < window.scrollY) {
          window.scrollTo(0, buttonRow.offsetTop)
        // if we'r'e too high, move the bottom of the video to the bottom of the screen
        } else if (videoElement.offsetTop+videoElement.getBoundingClientRect().height > window.scrollY+window.innerHeight) {
          window.scrollTo(0, videoElement.offsetTop+videoElement.getBoundingClientRect().height-window.innerHeight)
        }
      })
    },

    // not the preferred UX in the end but i'm leaving the method just in case
    setSpeedTestScroll () {
      this.$nextTick(()=>{
        let testRow = document.getElementById("speedTestRow")
        let buttonRow = document.getElementById("speedTestButtons")
        let resultRow = document.getElementById("speedTestResult")
        let testRowHeight = testRow.getBoundingClientRect().height-100 // i dont need to scroll to the copyright footer
        // if the viewport isn't big enough for everything, move the buttons to the top of the screen
        if(buttonRow.getBoundingClientRect().height + testRowHeight + resultRow.getBoundingClientRect().height > window.innerHeight) {
          window.scrollTo(0, buttonRow.offsetTop)
        // if the viewport is big enough for everything and
        // if we're too low, move the buttons to the top of the screen
        } else if(buttonRow.offsetTop < window.scrollY) {
          window.scrollTo(0, buttonRow.offsetTop)
        // if we'r'e too high, move the bottom of the test to the bottom of the screen
        } else if (testRow.offsetTop+testRowHeight > window.scrollY+window.innerHeight) {
          window.scrollTo(0, testRow.offsetTop+testRowHeight-window.innerHeight)
        }
      })
    },


    // encapsulates starting the preview stream
    startPreview(){
      if(!this.audioOnlyCall && this.localMediaStream){
        document.getElementById("textPexipVideoSelfPreview").srcObject = this.localMediaStream
        this.showPreview = true
      }
    },

    // when its disconnected, the test is over, so we  need to react to that
    // we get a reason but I dont think they matter for us
    pexipTestOnDisconnect (reason) {
      this.pexipTestStop()
    },

    // these are my convenience functions for handling embedded test stuff

    // parses PXIP errors, and displays messages to the user
    pexipHandleError(error) {

      // either I show the "no webcam" error, or I start the audio only test
      if(error.indexOf('camera/microphone')>=0 && !this.audioOnlyCall) {
        this.$nextTick(()=>{
          this.pexipTestStart(true)
        })
      } else {
        if(error.indexOf('camera/microphone')>=0) {
          this.showVisitTestEquipmentFailInfo = true
        } else if(error.indexOf('WebRTC not supported')>=0) {
          this.showVisitTestRtcFailInfo = true
        }
        this.visitTestFail()
      }
    },

    // main entry point to stop a test
    // resets the rtc library so we don't try to interact with the old test
    // the falseStop parameter is to indicate we are just resetting the test before starting a new one, and we shouldn't change the UI to a "test complete" state
    pexipTestStop(falseStop) {
      if(this.pexRtc){
        this.pexRtc.disconnect()
      }
      if(!falseStop){
        this.visitTestStartButtonName = this.s('entryPoint.visitTest.testStartButton.retest')
        this.showVisitTestCompleteButton = true
      }
      // stopping the tracks here doesn't do much but seems like a fine idea
      if(this.localMediaStream){
        this.localMediaStream.getTracks().forEach(function(track) {
          track.stop()
        })
      }
      // reset flags
      this.testLoading=false
      this.equipmentTestOngoing=false
      this.showPreview=false

      this.localMediaStream = null

      // if the call has been initiated but hasn't reached the onSetup callback, and we stop the test with this pexipTestStop method, pexip still grabs the webcam feed and then never releases it
      // so we clear the callbacks that would set up this stopped test call, and set a new callback to fix the issue of not releasing the feed
      if(this.pexRtc){
        this.pexRtc.onError = null
        this.pexRtc.onConnect = null
        this.pexRtc.onDisconnect = null
        let oldPexRtc = this.pexRtc
        // in this new callback, ets do everything we can to turn off the call and local media access
        this.pexRtc.onSetup = (localMediaStream) => {
          
          oldPexRtc.disconnect()
          oldPexRtc.muteVideo(true)
          oldPexRtc.muteAudio(true)
          localMediaStream.getTracks().forEach(function(track) {
            track.stop()
          })
        }
      }
      this.pexRtc = null

      if(document.getElementById("textPexipVideoSelfPreview").srcObject) {
        for(let track of document.getElementById("textPexipVideoSelfPreview").srcObject.getTracks()){
          track.stop()
        }
        document.getElementById("textPexipVideoSelfPreview").srcObject = null
      }
      if(document.getElementById("testPexipVideoStream").srcObject) {
        document.getElementById("testPexipVideoStream").srcObject = null
      }
    },

    pexipTestStart(audioOnly) {
      this.pexipTestStop(true)

      this.audioOnlyCall = audioOnly
      this.showVisitTestCompleteButton = false
      this.showBackToTestOptions = this.isTooEarlyForVisit()
      this.showVisitTestEquipmentFailInfo = false
      this.showVisitTestRtcFailInfo = false
      this.showVisitTestFailInfo = false
      this.showVisitTestSuccessInfo = false

      this.pexRtc = new window.PexRTC()
      this.pexRtc.onError = this.pexipTestOnError
      this.pexRtc.onConnect = this.pexipTestOnConnect
      this.pexRtc.onDisconnect = this.pexipTestOnDisconnect
      this.pexRtc.onSetup = this.pexipTestOnSetup
      
      let callType = undefined
      this.pexRtc.makeCall(process.env.VUE_APP_PEXIP_DOMAIN, 'test_call', this.clientName, null, this.audioOnlyCall?"audioonly":undefined)

      this.testLoading=true
      //https://docs.pexip.com/files/v21/Pexip_Client_PexRTC_API_v21.a.pdf
    },
    /**
     * The test visit was complete.  Carry on to the next section or show the message depending on if the client is too early for the visit to begin.
     */
    visitTestComplete () {
      this.pexipTestStop()
      if (this.isTooEarlyForVisit()) {
        this.showVisitTestSuccessInfo = true
        this.showVisitTestFailInfo = false
      } else {
        this.showSection(sectionNames.connect)
      }
    },
    /**
     * Just refresh for now to go back to the too early test options screen.
     */ 
    returnToTestOptions () {
      location.reload()
    },
    /**
     * The test visit was a not a success
     */
    visitTestFail () {
      this.pexipTestStop()
      this.progressValue = 4
      this.showVisitTestFailInfo = true
      this.showVisitTestSuccessInfo = false
    },

    endVisit () {
      this.showSection(sectionNames.complete)
    },
   /* openAppleStore() {
      window.open('https://apps.apple.com/ca/app/pexip-infinity-connect/id1195088102', '_blank')
    },
    openAndroidStore() {
      window.open('https://play.google.com/store/apps/details?id=com.pexip.infinityconnect', '_blank')
    },*/
    /**
     * Validate and launch the visit.
     */
    getPexipLinks () {
      this.loadingPexip = true
      swaggerRequest('readPexipUrl', { launchId: this.$route.params.visitId}, (response) => {
          if (typeof response === 'object' && response.error) {
            // show something about not being set
          } else {
            this.appUrl = response.appUrl
            this.browserUrl = response.url
            this.loadingPexip = false
          }
        })
    },

    initVisit () {
      this.confirmPexLibrary()

      for (const key in this.initialValues) {
        this[key] = this.initialValues[key]
      }
      // always check browser compatability
      if (!this.isBrowserSupported()) {
        this.showSection(sectionNames.unsupportedBrowser)
        return
      }

      if (!this.$route.params.visitId) {
        this.showSection(sectionNames.invalidVisit)
        return
      }
      // load in the visit information
      swaggerRequest('readVisit', { visitId: this.$route.params.visitId }, (response) => {
        //let response = {"isVisitInPast":false,"status":"Booked","statusFr":"","providerName":"Dr Chris Effertz","clientName":"f f","clinicName":"Bergnaum Inc","clinicEmail":"a@fakeEmail.com","clinicPhone":"104-981-6320","startTime":"2020-06-22T16:05:00","durationMinutes":15,"meetingUrl":"https://testmeet.mbtelehealth.ca/meet.omnis?pin=49713&name=f%20f","pexipUrl":"https://testmeet.mbtelehealth.ca","tooEarlyForVisitMinutes":30,"suggestedEarlyForVisitMinutes":15}
        if (response && response.error === 'VISIT_NOT_FOUND') {
          this.showSection(sectionNames.invalidVisit)
          return
        }
        this.isVisitInPast = response.isVisitInPast
        if(this.isVisitInPast) {
          this.showSection(sectionNames.pastVisit)
          return
        } else {
          this.status = response.status
          this.statusFr = response.statusFr
          this.providerName = response.providerName
          this.clientName = response.clientName
          this.clinicName = response.clinicName
          this.clinicEmail = response.clinicEmail
          this.clinicPhone = response.clinicPhone
          this.startTime = response.startTime
          let startTimeTokens = this.startTime.split(/[^0-9]/)
          this.startTimeDate = new Date(startTimeTokens[0], (startTimeTokens[1]-1), startTimeTokens[2], startTimeTokens[3],startTimeTokens[4], startTimeTokens[5])
          this.visitDateStr = date.format(this.startTimeDate, 'MMMM DD, YYYY')
          this.visitTimeStr = date.format(this.startTimeDate, 'h:mm A')
          this.durationMinutes = response.durationMinutes
          this.pexipUrl = response.pexipUrl
          this.tooEarlyForVisitMinutes = response.tooEarlyForVisitMinutes
          this.suggestedEarlyForVisitMinutes = response.suggestedEarlyForVisitMinutes
          this.isConfirmed = response.isConfirmed
          this.launchType = response.launchType

          if (this.isTooEarlyForVisit() || !this.isConfirmed) {
            this.showSection(sectionNames.tooEarly)
            return
          }
          this.showSection(sectionNames.speedTest)
        }
      })
    },
    confirmPexLibrary() {
      let vueObj = this
      this.pexLoadChecker = setInterval(function(){
        if (window.PexRTC) {
          vueObj.pexLoaded = true
          clearInterval(vueObj.pexLoadChecker)
        }
      }, 500)
    },
    startSpeedTest() {
      // hide and re-display the iframe, starting the test
      this.speedTestFailed = false
      this.speedTestNextStep = false
      this.stopSpeedTest(true)
      this.$nextTick(()=>{
        this.speedTestRunning = true
        this.showSpeedTestElement = true
        this.mountTestLoadListener()
      })
    },
    stopSpeedTest(falseStop) {
      if(!falseStop){
        this.speedTestStartButtonName = this.s('entryPoint.speedTest.restartSpeedTestButton')
        this.speedTestStopped = true
      }
      this.speedTestRunning = false
      this.showSpeedTestElement = false
      this.showBackToTestOptions = this.isTooEarlyForVisit()
      
    },
    skipSpeedTest(){
      this.speedTestStopped = true
      this.speedTestRunning = false
      this.showSpeedTestElement = false
      this.speedTestStartNextStep()
    },
    // if this callback works properly (when the test ends instead of when it starts)
    // we can just show the buttons when the test ends, or automatically interpret the results for the user
    speedTestCompleteHandler(event) {
      if (event.origin !== 'https://sharedhealthmb.speedtestcustom.com') {
        return;
      }
      this.speedTestStartButtonName = this.s('entryPoint.speedTest.restartSpeedTestButton')
      this.speedTestRunning = false
      this.speedTestComplete = true
      this.showBackToTestOptions = this.isTooEarlyForVisit()
      if(event.data.download >= 1000 && event.data.upload >= 1000 /*&& false*/){
        this.speedTestSuccess()
      } else {
        this.speedTestFail()
      }
    },
    speedTestSuccess() {
      this.speedTestNextStep = true
      this.speedTestFailed = false
    },
    speedTestFail() {
      this.speedTestNextStep = false
      this.speedTestFailed = true
    },
    speedTestStartNextStep() {
      this.showSection(sectionNames.visitTest)
    },
    // now that I am listening for when the test is completed, there is no reason to listen for its load
    // but I'm keeping it around
    mountTestLoadListener() {
      this.$nextTick(()=>{
        let vueObj = this
        document.getElementById("speedTestPage").onload = function(){
          vueObj.speedTestLoaded = true
        }
      })
    },

    transitionToReconnect() {
      if(!this.loadingPexip){
        this.showSection(sectionNames.reconnect)
      }
    }

  },
  computed: {
    pexipButtonClass() {
      if(this.loadingPexip){
        return 'btn btn-primary disabled'
      } else {
        return 'btn btn-primary'
      }
    },
    
    testCompleteButtonMdSize() {
      if(this.showVisitTestCompleteButton && this.showBackToTestOptions) {
        return "6"
      } else {
        return "4"
      }
    },
    hasMobileApp(){
      return ( /Android|iPhone|iPad/i.test(navigator.userAgent) )
    },
    hasMobileAppApple(){
      return ( /iPhone|iPad/i.test(navigator.userAgent) )
    },
    hasMobileAppAndroid(){
      return ( /Android/i.test(navigator.userAgent) )
    },
    loadingPexipTitle(){
      if(this.loadingPexip){
        return this.s('entryPoint.connect.loadingPexip')
      } else {
        return null
      }
      
    }
    
  },
  created () {
    this.initVisit()
    if (window.addEventListener) {
      window.addEventListener("message", this.speedTestCompleteHandler);
    } // IE backwards compatibility 
    else if (window.attachEvent) {
      window.attachEvent("onmessage", this.speedTestCompleteHandler);
    }
  },
  // can probably get rid of mounted if we can be alerted when the test ends, as we can just show the buttons when the test ends
  // or automatically interpret the results for the user
  mounted () {

  },
  watch: {
    $route (to, from) {
      this.initVisit()
    }
  }
}
</script>

<style lang="scss">
  $primary: #517e89;

  #entryPoint .entrySectionTitle {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.75em;
  }

  #entryPoint .mockup {
    color: red;
  }

  #entryPoint .warnText {
    color: red;
  }

  #entryPoint .buttonRow {
    margin-top: 1em;
  }

  #entryPoint div.buttonRow button{
    width:100%;
  }

  #entryPoint div.buttonRow a.btn{
    width:100%;
  }

  #entryPoint a.btn  {
    color: white;
    font-weight: normal;
  }

  #entryPoint div.buttonRow {
    padding-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }

  #entryPoint div.buttonRow>div {
    margin-top:0.15em;
    padding-left: 0.15em;
    padding-right: 0.15em;
  }

  #entryPoint #testPexipVideoStreamContainer {
    margin-bottom: 1em;
  }

  #entryPoint #loadingSpinner>div {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  #entryPoint #textPexipVideoSelfPreviewContainer {
    position: fixed;
    top: 1em;
    right: 1em;
    z-index: 100;
    width: 25%;
    max-width: 320px;
  }

  #entryPoint #speedTestResult {
    min-height: 3em;
  }

  #entryPoint ol {
    margin-bottom: 0.1em;
  }

  #entryPoint .testResultIcon {
    padding-right: 0.5em;
    min-width: 3em;
  }

  #entryPoint div.testResult td, #entryPoint div.testResult table {
    border: 0;
  }

  #entryPoint svg.passIcon {
    color: green;
    min-height: 1em;
  }

  #entryPoint svg.failIcon {
    color: red;
    min-height: 1em;
  }

  #entryPoint td.testResultDescription {
    width: 100%;
  }

  #entryPoint div.pexipLoadingMessage {
    color: $primary;
    font-weight: bold;
  }

  #entryPoint div.pexipLoadingRow {
    margin-top: 0.5em;
  }

</style>
