import Vue from 'vue'
import VueRouter from 'vue-router'
import EntryPoint from '../views/EntryPoint.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:visitId',
    name: 'EntryPoint',
    component: EntryPoint,
    meta: { titleKey: 'title.entryPoint' }
  },
  {
    path :'*',
    name: 'NotFound',
    component:EntryPoint,
    meta: { titleKey: 'title.entryPoint' }
  }
]

const router = new VueRouter({
  routes
})

export default router
