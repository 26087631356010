<template>
  <b-row v-show="showVisitCountdown">
    <b-col>
      {{startsIn}} <strong>{{timeCountdown}}</strong>.
    </b-col>
  </b-row>
</template>
<script>
import countdown from 'countdown'
export default {
  name: 'Countdown',
  props: {
    startTimeDate: {
      required: true
    }
  },
  data () {
    return {
      timeCountdown: '',
      showVisitCountdown: false,
      countdownInterval: null
    }
  },
  computed: {
    startsIn () {
      return this.s('entryPoint.header.startIn')
    }
  },
  methods: {
    startCountdownInterval () {
      if(this.countdownInterval){
        clearInterval(this.countdownInterval)
      }
      this.timeCountdown = countdown(null, this.startTimeDate).toString()
      this.showVisitCountdown = this.startTimeDate.getTime() > Date.now()
      // if we show the countdown then start up the interval to keep it updated
      if (this.showVisitCountdown) {
        this.countdownInterval = setInterval(() => {
          this.timeCountdown = countdown(null, this.startTimeDate).toString()
          // chek if we need to hide the countdown and stop the interval
          this.showVisitCountdown = this.startTimeDate.getTime() > Date.now()
          if (!this.showVisitCountdown) { 
            clearInterval(this.countdownInterval)
          }
        }, 1000)
      }
    }
  },
  watch: {
    // need to wait for the callback in the parent to complete
    startTimeDate () {
      if (this.startTimeDate) {
        this.startCountdownInterval()
      }
    }
  },
  mounted () {
    if(this.startTimeDate){
      this.startCountdownInterval()
    }
  }
}
</script>

<style lang='scss'>
</style>
