
'using strict'

// Shared utility functions for our applications.
// TODO This and the corresponding tests should be moved into a node module.
import _ from 'lodash'
import store from './store'
import { strings as englishStrings } from '@/strings/english.js'
import { strings as frenchStrings } from '@/strings/french.js'
// import NProgress from '@/../node_modules/nprogress/nprogress.js'
import Swagger from 'swagger-client'
// import store from './store.js'

// const store = import('./store.js')

/** This will hold the swagger client once it has been created. */
var swaggerClient = null

/* export function dateFormats () {
  return {
    date: 'DD-MMM-YYYY',
    dateTime: 'DD-MMM-YYYY HH:mm:ss',
    mysqlTimestampFormat: 'YYYY-MM-DD HH:mm:ss'
  }
}

export function defaultDateConfig () {
  return {
    format: dateFormats().date,
    minDate: moment('01-Jan-2019', dateFormats().date),
    useCurrent: false,
    icons:{
      time: 'fa fa-clock',
      date: 'fa fa-calendar-alt',
      up: 'fa fa-chevron-up',
      down: 'fa fa-chevron-down',
      previous: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      today: 'fa fa-crosshairs',
      clear: 'fa fa-trash-alt',
      close: 'fa fa-times-circle'
    }
  }
} */

/**
 * return the localized string based on the key.
 */
export function s (key) {
  let string = ''
  if (getLang().toUpperCase().includes('FR')) {
    string = frenchStrings[key]
    if (string !== undefined) {
      return string
    }
  }
  string = englishStrings[key]
  if (string === undefined) {
    return '!UNDEFINED!'
  }
  return string
}

export function getLang () {
  if (navigator.languages !== undefined) { return navigator.languages[0] } else { return navigator.language }
}

export function trim (value) {
  if (value) {
    if (typeof value === 'string') {
      value = value.trim()
    } else if (typeof value === 'object') {
      Object.keys(value).forEach((field) => {
        value[field] = trim(value[field])
      })
    }
  }

  return value
}

/**
 * Make a swagger rest request.
 * @param {string} swaggerClientFunction
 * @param {object} params
 * @param {function} callback
 */
export function swaggerRequest (swaggerClientFunction, params, callback) {
  // store.then(res => {res.dispatch('showButtonLoadingSpinner')})
  // store.dispatch('showButtonLoadingSpinner')
  // NProgress.start()
  swaggerRequestFunc()
    .catch((error) => {
      console.error(`Could not make swagger request to: ${process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL} for function call: ${swaggerClientFunction}, with params:`)
      console.error(params)
      console.error(error.message)
      if (error.response) console.error(error.response.body)
      errorMessageFunction(
        {
          function: 'swaggerRequest',
          swaggerUrl: process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL,
          swaggerFunction: swaggerClientFunction,
          statusText: error.message,
          errorMessage: error.response ? error.response.body : ''
        }
      )
      // store.dispatch('hideButtonLoadingSpinner')
      // NProgress.done()
    })

  async function swaggerRequestFunc () {
    if (_.isEmpty(swaggerClient)) {
      swaggerClient = await Swagger(process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL)
      if (process.env.VUE_APP_FORCE_SWAGGER_SCHEME) {
        swaggerClient.spec.schemes = [process.env.VUE_APP_FORCE_SWAGGER_SCHEME]
      }
      swaggerClient.http.withCredentials = true
    }
    swaggerClient.requestInterceptor = function (req) {
      req.headers[process.env.VUE_APP_API_KEY_HEADER] = process.env.VUE_APP_API_KEY
    }
    const response = await swaggerClient.apis.default[swaggerClientFunction](params)
    // when swagger sends a file there is no body
    try {
      callback(typeof response.text === 'string' ? response.body : response.data)
    } catch (e) {
      console.error(`Error in callback for: ${process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL} for function call: ${swaggerClientFunction}, with params:`)
      console.error('The swagger function completed but the callback threw an exception')
      console.error(e)
      errorMessageFunction(
        {
          function: 'swaggerRequest -> callback',
          swaggerUrl: process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL,
          swaggerFunction: swaggerClientFunction,
          callbackFunction: callback,
          errorMessage: e.message
        }
      )
    }
    // store.dispatch('hideButtonLoadingSpinner')
    // NProgress.done()
  }
}

var errorMessageFunction = function (errorInfo) {
  console.error('!!The error message function should be overridden to show information to the user!!')
  console.error(errorInfo)
}
export function setShowErrorMessageFunction (newErrorMessageFunction) {
  errorMessageFunction = newErrorMessageFunction
}

/**
 * Create mappings to vuex for computed fields in a component.
 *
 * Each mapping will be of the form:
 *   fieldName: {
 *     get() { return this.$store.state.module.stateObjNamefield },
 *     set(value) { this.$store.commit('mutationName', {fieldName: value}) }
 *   }
 *
 * This is a shorthand if:
 * - The field are all in a single object in a store module
 * - there is a mutation for the entire object that allows for selectively updating fields
 *
 * e.g.
 *
 * store module
 * ------------
 * export default {
 *   ...
 *   state: {
 *     myObject: {
 *       field1,
 *       field2,
 *       ...
 *     }
 *   },
 *   actions: {
 *     ...
 *   },
 *   mutations: {
 *     SET_MY_OBJECT (state, myObject) {
 *       Object.keys(myObject).forEach((k) => {
 *         state.myObject[k] = myObject[k]
 *       })
 *     }
 *   }
 * }
 *
 * Vue Component
 * -------------
 * computed: {
 *  ...mapVuexObjectFields('myModule', 'myObject', 'SET_MY_OBJECT', [
 *    'field1',
 *    'field2',
 *    ...
 *  ])
 *
 * @param {string} moduleName
 * @param {string} stateObjName
 * @param {string} mutationName
 * @param {string[]} fieldNames
 */
export function mapVuexObjectFields (moduleName, stateObjName, mutationName, fieldNames) {
  const mappingObj = {}
  for (const fieldName of fieldNames) {
    mappingObj[fieldName] = {
      get () {
        return store.state[moduleName][stateObjName][fieldName]
      },
      set (value) {
        const valueObj = {}
        valueObj[fieldName] = value
        store.commit(`${moduleName}/${mutationName}`, valueObj)
      }
    }
  }
  return mappingObj
}

/* export function mapVuexFields(moduleName, mutationName, fieldNames) {
  let mappingObj = {}
  for(let fieldName of fieldNames) {
    mappingObj[fieldName] = {
      get() {
        return store.state[moduleName][stateFieldName]
      },
      set(value) {
        store.commit(`${moduleName}/${mutationName}`, {field: fieldName, value: value})
      }
    }
  }
  return mappingObj
} */

// mostly used for automatic updates that should be delayed and aggregated (aut-refresh search screens, auto-save forms)
const EXECUTE_DELAY_MILLISECONDS = 300
var delayedCalls = {}
export function delayedExecute (name, delayFunc, timeout, params) {
  const existingCall = delayedCalls[name]
  if (existingCall !== undefined) {
    clearTimeout(existingCall.timeout)
  }
  delayedCalls[name] = {
    timeout: setTimeout(function () { delayFunc(params) }, timeout || EXECUTE_DELAY_MILLISECONDS)
  }
}
// /**
//  * Make a swagger rest request.
//  * @param {string} swaggerClientFunction
//  * @param {object} params
//  * @param {function} callback
//  */
// export function swaggerRequest (swaggerClientFunction, params, callback) {
//   // store.then(res => {res.dispatch('showButtonLoadingSpinner')})
//   store.dispatch('showButtonLoadingSpinner')
//   NProgress.start()
//   swaggerRequestFunc()
//     .catch((error) => {
//       if (error.status === 401 || error.status === 403) {
//         window.location.replace(`${process.env.VUE_APP_LOGIN_PORTAL_URL}?frontEndUrl=${encodeURI(window.location).replace('#', '%23')}`)
//       } else {
//         console.error(`Could not make swagger request to: ${process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL} for function call: ${swaggerClientFunction}, with params:`)
//         console.error(params)
//         console.error(error.message)
//         if (error.response) console.error(error.response.body)
//         errorMessageFunction(
//           {
//             'function': 'swaggerRequest',
//             'swaggerUrl': process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL,
//             'swaggerFunction': swaggerClientFunction,
//             'statusText': error.message,
//             'errorMessage': error.response ? error.response.body : ''
//           }
//         )
//         store.dispatch('hideButtonLoadingSpinner')
//         NProgress.done()
//       }
//     })

//   async function swaggerRequestFunc () {
//     if (_.isEmpty(swaggerClient)) {
//       swaggerClient = await Swagger(process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL)
//       if (process.env.VUE_APP_FORCE_SWAGGER_SCHEME) {
//         swaggerClient.spec.schemes = [process.env.VUE_APP_FORCE_SWAGGER_SCHEME]
//       }
//       swaggerClient.http.withCredentials = true
//     }
//     let response = await swaggerClient.apis.default[swaggerClientFunction](params)
//     // when swagger sends a file there is no body
//     try {
//       callback(typeof response.text === 'string' ? response.body : response.data)
//     } catch (e) {
//       console.error(`Error in callback for: ${process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL} for function call: ${swaggerClientFunction}, with params:`)
//       console.error(`The swagger function completed but the callback threw an exception`)
//       console.error(e)
//       errorMessageFunction(
//         {
//           'function': 'swaggerRequest -> callback',
//           'swaggerUrl': process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL,
//           'swaggerFunction': swaggerClientFunction,
//           'callbackFunction': callback,
//           'errorMessage': e.message
//         }
//       )
//     }
//     store.dispatch('hideButtonLoadingSpinner')
//     NProgress.done()
//   }
// }

// var errorMessageFunction = function (errorInfo) {
//   console.error('!!The error message function should be overridden to show information to the user!!')
//   console.error(errorInfo)
// }
// export function setShowErrorMessageFunction (newErrorMessageFunction) {
//   errorMessageFunction = newErrorMessageFunction
// }

// ********************************************************************************************************************************************************
// Global Debug Functions

/**
 * Print out all of the functions available in the swagger client.
 */
window.debugListSwaggerClientFunctions = function () {
  Swagger(process.env.VUE_APP_BACKEND_SWAGGER_FILE_URL)
    .then((debugSwaggerClient) => {
      let out = `Swagger Client Functions for ${debugSwaggerClient.url}\n`
      let currEndPoint = ''
      Object.keys(debugSwaggerClient.apis.default).map((key) => {
      // the function name is just the key
        const funcName = key
        // the end point name will have the crud prefix removed
        let endPointName = key
        endPointName = endPointName.replace(/^create/, '')
        endPointName = endPointName.replace(/^read/, '')
        endPointName = endPointName.replace(/^update/, '')
        endPointName = endPointName.replace(/^delete/, '')
        // the orderByName name is the key, all lowercase, with the crud prefix removed and an ordinal value appended (to maintain CRUD order)
        let orderByName = key
        if (orderByName.startsWith('create')) { orderByName = orderByName.replace(/^create/, '').toLowerCase() + '_1' }
        if (orderByName.startsWith('read')) { orderByName = orderByName.replace(/^read/, '').toLowerCase() + '_2' }
        if (orderByName.startsWith('update')) { orderByName = orderByName.replace(/^update/, '').toLowerCase() + '_3' }
        if (orderByName.startsWith('delete')) { orderByName = orderByName.replace(/^delete/, '').toLowerCase() + '_4' }

        return { funcName: funcName, endPointName: endPointName, orderByName: orderByName }
      })
        .sort((a, b) => {
          if (a.orderByName < b.orderByName) {
            return -1
          } else if (a.orderByName > b.orderByName) {
            return 1
          } else {
            return 0
          }
        })
        .forEach((e) => {
          if (currEndPoint !== e.endPointName) {
            out += '\n'
            currEndPoint = e.endPointName
          }
          out += `${e.funcName}\n`
        })

      console.log(out)
    })
}
