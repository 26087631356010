import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// ------- bootstrap -------
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// ------- font awesome -------
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'

// ------- date time picker -----
/* import DatePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons/faCrosshairs'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'*/
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'

// ------- string localization -------
import { s } from '@/appDevSharedUtils.js'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Vue.use(DatePicker)
library.add(faExclamationTriangle)
library.add(faCheckCircle)
// -- for date time picker --
/* library.add(faClock)
library.add(faCalendarAlt)
library.add(faChevronUp)
library.add(faChevronDown)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faCrosshairs)
library.add(faTrashAlt) */
library.add(faTimesCircle)
// -------------------------
dom.watch()
Vue.component('font-awesome-icon', FontAwesomeIcon)
// register the string function so that it is available globally
Vue.prototype.s = s

Vue.config.productionTip = false

router.beforeEach(async (to, from, next) => {
  // I guess that back end logic will decide what information to send us for these requests...
  /* await store.dispatch('fetchLookups')
  await store.dispatch('fetchCurrentUser') */
  next()
})

// this code fixes an issue on IE11, where a user changing the URL after the hash has no effect on page routing
const IE11RouterFix = {
  methods: {
    hashChangeHandler: function () { this.$router.push(window.location.hash.substring(1, window.location.hash.length)) },
    isIE11: function () { return !!window.MSInputMethodContext && !!document.documentMode }
  },
  mounted: function () { if (this.isIE11()) { window.addEventListener('hashchange', this.hashChangeHandler) } },
  destroyed: function () { if (this.isIE11()) { window.removeEventListener('hashchange', this.hashChangeHandler) } }
}

new Vue({
  mixins: [IE11RouterFix],
  router,
  store,
  render: h => h(App)
}).$mount('#app')
