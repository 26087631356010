<template>
  <div id="app">
    <router-view />
    <b-modal ref="errorModal" hide-footer hide-header no-close-on-esc no-close-on-backdrop>
      <div class="d-block text-center">
        <b-container>
          <b-row align-v="center">
            <b-col cols="2" class="exclamationTriangle">
              <font-awesome-icon icon="exclamation-triangle" size="3x" />
            </b-col>
            <b-col>
              <b-row class="centerContent">
                {{s('errorMessage')}}
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { setShowErrorMessageFunction } from '@/appDevSharedUtils.js'

export default {
  mounted: function () {
    setShowErrorMessageFunction(() => {
      this.$refs.errorModal.show()
    })
  },
  created: function () {
    window.PexRTC = null
    let pexipScript = document.createElement('script')
    pexipScript.setAttribute('src', `https://${process.env.VUE_APP_PEXIP_DOMAIN}/static/webrtc/js/pexrtc.js`)
    pexipScript.setAttribute('async', '')
    pexipScript.setAttribute('defer', '')
    document.head.appendChild(pexipScript)
  }
}
</script>

<style lang="scss">
  // global styling for the vuejs-dialog library
  @import '@/assets/bootstrap_skin.scss';

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #444;
    min-width: 340px;
  }
</style>
