'using strict'

export var strings = {

  'title.entryPoint': 'Virtual Visit',

  'entryPoint.header.welcome': 'Welcome',
  'entryPoint.header.visitInfo_1': 'Your',
  'entryPoint.header.visitInfo_2': 'minute',
  'entryPoint.header.visitInfo_3': 'visit with',
  'entryPoint.header.visitInfo_4': 'from clinic',
  'entryPoint.header.visitInfo_5': 'is scheduled to be on',
  'entryPoint.header.visitInfo_6': 'at',
  'entryPoint.header.startIn': 'It will start in',
  'entryPoint.header.clinicInfo': 'If you have any questions or concerns, please contact us at ',

  'entryPoint.unsupportedBrowser.title': 'Unsupported Browser',
  'entryPoint.unsupportedBrowser.info.top': 'Sorry, the browser that you are using is not compatible with virtual visits.  One of the following browsers must be used:',
  'entryPoint.unsupportedBrowser.info.li1': 'Google Chrome version 61 and later (64-bit only)',
  'entryPoint.unsupportedBrowser.info.li2': 'Mozilla Firefox version 68 and later',
  'entryPoint.unsupportedBrowser.info.li3': 'Microsoft Edge version 41 and later (including Edge Chromium)',
  'entryPoint.unsupportedBrowser.info.li4': 'Opera version 53 and later',
  'entryPoint.unsupportedBrowser.info.li5': 'Apple Safari version 11.1 and later on macOS',
  'entryPoint.unsupportedBrowser.info.li6': 'Apple Safari on iOS 11.2 and later',
  'entryPoint.unsupportedBrowser.info.li7': 'Any other browser that supports WebRTC',

  'entryPoint.invalidVisit.title': 'Invalid Visit ID',
  'entryPoint.invalidVisit.info.top': 'Invalid Visit ID',
  'entryPoint.invalidVisit.info': 'Error: Invalid Visit ID',

  'entryPoint.pastVisit.title': 'Virtual Visit Has Ended',
  'entryPoint.pastVisit.msg': 'This visit has ended and is no longer accessible.',

  'entryPoint.tooEarlyMobile.msg_1': 'Your virtual visit can be opened in two ways, using the browser or using the mobile app. The mobile app may provide a better experience in your virtual visit but requires you to install it on your device. Click ',
  'entryPoint.tooEarlyMobile.msg_2': ' if you are interested. Note that this is an optional step.',

  'entryPoint.tooEarly.title': 'Your Visit is Not Scheduled to Begin Yet',
  'entryPoint.tooEarly.msg.1': 'Please complete a speed and equipment test to ensure that you are able to connect on the day of your virtual visit. Make sure to test using the device you plan on using the day of your visit.',
  'entryPoint.tooEarly.msg.2_1': 'Click ',
  'entryPoint.tooEarly.msg.2_2': ' to begin.',
  'entryPoint.tooEarly.testStartButton': 'Start Tests',

  'entryPoint.confirmVisit.title': 'Virtual Visit Confirmation',
  'entryPoint.confirmVisit.confirmVisit': 'Confirm Visit',
  'entryPoint.confirmVisit.msg.1_1': 'Click ',
  'entryPoint.confirmVisit.msg.1_2': ' to let us know you are able to attend your visit.',
  'entryPoint.confirmVisit.msg.2': 'Thank you for confirming your visit, it is not scheduled to begin yet.',


  'entryPoint.toast.email.fail.body': 'Error sending email, please contact clinic',
  'entryPoint.toast.email.fail.title': 'Error',
  'entryPoint.toast.email.success.body': 'Email sent successfully',
  'entryPoint.toast.view.email.success.title': 'Success',

  'entryPoint.speedTest.title': 'Internet Speed Test',
  /*'entryPoint.speedTest.mockup.1': 'This is a Mockup',
  'entryPoint.speedTest.mockup.2': 'We can use one of the free speed test widgets (http://openspeedtest.com/, https://sourceforge.net/speedtest/, https://github.com/librespeed/speedtest) to run the test here.',
  'entryPoint.speedTest.mockup.3': 'We can likely make this work with a pass/fail as well (but it might be a bit of work).  I just went with the easiest option for the mockup.',
  'entryPoint.speedTest.info.top': 'This is an optional speed test to ensure that your internet speed is fast enough for your virtual visit.\n' +
    'In order to run the speed test please go through the following steps.  (If you are confident that your speed is sufficient feel free to skip this test).',
  'entryPoint.speedTest.info.li1_1': 'Click on the',
  'entryPoint.speedTest.info.li1.testButtonName': 'Start',
  'entryPoint.speedTest.info.li1_2': 'button and allow the speed to test run.',
  'entryPoint.speedTest.info.li2': 'A table of information will be provided. In that table ensure that:',
  'entryPoint.speedTest.info.li2.1': 'Your Download speed is at least ##',
  'entryPoint.speedTest.info.li2.2': 'Your Upload speed is at least ##',
  'entryPoint.speedTest.info.li3_1': 'Click on the',
  'entryPoint.speedTest.info.li3_2': 'button to continue.',*/

  'entryPoint.speedTest.info.para1': 'This will test your internet speed to ensure it is fast enough for your virtual visit.',
  'entryPoint.speedTest.steps.intro1': 'Please go through the following steps:',
  'entryPoint.speedTest.steps.li1_1': 'Click ',
  'entryPoint.speedTest.steps.li1_2': ' and allow the test to run.',
  'entryPoint.speedTest.steps.li2': 'When the test is complete you will be provided instructions on how to proceed.',
  'entryPoint.speedTest.steps.li3': 'If you cannot run the test, click ',
  'entryPoint.speedTest.complete.failed_1': 'Your speed test is unsuccessful. Click ',
  'entryPoint.speedTest.complete.failed_2': ' to try again or contact your clinic at ',
  'entryPoint.speedTest.complete.failed_3': ' to make alternate visit arrangements.',
  'entryPoint.speedTest.complete.passed1': 'Your speed test is successful, no internet speed issues are anticipated when you start your visit at the scheduled time.',
  'entryPoint.speedTest.complete.passed2_1': ' Please note that we encourage you to click the link ',
  'entryPoint.speedTest.complete.passed2_2': ' minutes',
  'entryPoint.speedTest.complete.passed2_3': ' prior to the scheduled start time.',
  'entryPoint.speedTest.complete.next_step_1': 'Your speed test is successful, click ',
  'entryPoint.speedTest.complete.next_step_2': ' to proceed.',

  'entryPoint.speedTest.returnToTestOptionsButton': 'Return to Test Options',
  'entryPoint.speedTest.stopSpeedTestButton': 'Stop Speed Test',
  'entryPoint.speedTest.restartSpeedTestButton': 'Restart Speed Test',
  'entryPoint.speedTest.nextStepButton': 'Next Step',
  'entryPoint.speedTest.skipTestButton': 'Skip Speed Test',
  'entryPoint.speedTest.testFailButton': 'Test Unsuccessful',
  'entryPoint.speedTest.testPassButton': 'Test Successful',
  'entryPoint.speedTest.startSpeedTest': 'Start Speed Test',

  'entryPoint.visitTest.title': 'Equipment Test',
  'entryPoint.visitTest.info.top_1': 'This will test your device to ensure that it is able to function correctly for your virtual visit.',
  'entryPoint.visitTest.info.top_2': 'Please go through the following steps:',
  'entryPoint.visitTest.info.li1_1': 'Click ',
  'entryPoint.visitTest.info.li1_2': '.',
  'entryPoint.visitTest.info.li2': 'A test call will begin. Follow the instructions and confirm that your microphone and camera are working.',
  'entryPoint.visitTest.info.li3_1': 'When the test is complete, click ',
  'entryPoint.visitTest.info.li3_2': ' or ',
  'entryPoint.visitTest.info.li3_3': ' to proceed.',
  'entryPoint.visitTest.info.success': 'Your equipment test is successful, all tests are now completed. Please close your browser tab.',
  'entryPoint.visitTest.info.fail_1': 'Your equipment test is unsuccessful. Click ',
  'entryPoint.visitTest.info.fail_2': ' to try again or contact your clinic at ',
  'entryPoint.visitTest.info.fail_3': ' to make alternate visit arrangements.',
  'entryPoint.visitTest.testStartButton': 'Test Equipment',
  'entryPoint.visitTest.testStartButton.retest': 'Retest Equipment',
  'entryPoint.visitTest.testFailButton': 'Test Unsuccessful',
  'entryPoint.visitTest.testPassButton': 'Test Successful',
  'entryPoint.visitTest.stopTestButton': 'Stop Test',
  'entryPoint.visitTest.returnToTestOptionsButton': 'Return to Test Options',
  'entryPoint.visitTest.info.equipment_fail': 'The automated test is reporting that you do not have a camera or microphone. It is possible your web browser is blocking access, or another application has locked the webcam. Please allow access and try again.',
  'entryPoint.visitTest.info.rtc_fail': 'The automated test is reporting that your browser does not support real time communication. It is possible your web browser is blocking access to these features. Please allow access and try again.',
  'entryPoint.visitTest.info.audioTestDescription1': 'No camera detected, performing audio only test.',
  'entryPoint.visitTest.info.audioTestDescription2':'Ensure your microphone and headset/speakers are on.',
  'entryPoint.visitTest.info.noCamera': 'The automated test is reporting that you do not have a camera. It is possible your web browser is blocking access, or another application has locked the webcam. Please allow access and try again.',

  'entryPoint.connect.title': 'Begin Your Virtual Visit',
  'entryPoint.connect.msg_1_1': 'Click ',
  'entryPoint.connect.msg_1_2': '. Please be patient, your provider may have a previous visit that has run longer than expected.',
  'entryPoint.connect.msg_2': 'The visit will open a new tab, close it when your visit is complete.',
  'entryPoint.connect.beginButton': 'Begin Virtual Visit',
  'entryPoint.connect.beginButtonBrowser': 'Begin Virtual Visit in Browser',

  'entryPoint.connect.browser': 'Browser',
  'entryPoint.connect.browser.step_1_1': 'Click ',
  'entryPoint.connect.browser.step_1_2':'. Please be patient, your provider may have a previous visit that has run longer than expected.',
  'entryPoint.connect.browser.step_2': 'The browser requires no installation and launches a new tab.',
  'entryPoint.connect.browser.step_3': 'Close the tab when your visit is complete.',

  'entryPoint.connect.app': 'Mobile App',
  'entryPoint.connect.app.step_1_1': 'Click ',
  'entryPoint.connect.app.step_1_2': ' (if you have not already done so).',
  'entryPoint.connect.app.step_2_1': 'Come back to this page and click ',
  'entryPoint.connect.app.step_2_2': '. Please be patient, your provider may have a previous visit that has run longer than expected.',
  'entryPoint.connect.app.step_3': 'Close the app when your visit is complete.',

  'entryPoint.connectMobile.title': 'Try Mobile App',
  'entryPoint.connectMobile.msg': 'Your virtual visit can be opened in two ways, using the browser or using the mobile app. The mobile app may provide a better experience in your virtual visit but requires you to install it on your device.',
  'entryPoint.connectMobile.downloadAndroid': 'Download the App',
  'entryPoint.connectMobile.downloadApple': 'Download the App',
  'entryPoint.connectMobile.begin': 'Begin Virtual Visit in App',

  'entryPoint.connect.loadingPexip': 'Please wait while your visit is created',

//https://apps.apple.com/ca/app/pexip-infinity-connect/id1195088102
//https://play.google.com/store/apps/details?id=com.pexip.infinityconnect&hl=en_CA

  // 'entryPoint.connect.info.header': 'You are now ready to begin your virtual appointment.',
  // 'entryPoint.connect.info.form.clientName.label': 'Please enter your name.',
  // 'entryPoint.connect.info.form.clientName.placeHolder': 'First and Last Name',
  // 'entryPoint.connect.info.form.careProvider.label': 'Select the care provider you have an appointment with.',
  // 'entryPoint.connect.info.form.careProvider.defaultOption': '--Select Care Provider--',
  // 'entryPoint.connect.info.footer_1': 'Click the',
  // 'entryPoint.connect.info.footer_2': 'button to start your virtual appointment. The appointment will open a new tab, close it when your appointment is complete.',
  // 'entryPoint.connect.beginButton': 'Begin Virtual Appointment',
  // 'entryPoint.connect.validation.toast.title': 'Could not begin appointment',
  // 'entryPoint.connect.validation.toast.message': 'Please ensure that you have entered your name and selected your care provider.',

  'entryPoint.reconnect.title': 'Your Visit Has Started',
  'entryPoint.reconnect.msg_11': 'If you have accidentally disconnected, click ',
  'entryPoint.reconnect.msg_12': '.',

  'entryPoint.reconnectApp.msg_11': 'If you have accidentally disconnected or would like to change between the two visit methods (browser or app), click the corresponding button - ',
  'entryPoint.reconnectApp.msg_12': ' or ',
  'entryPoint.reconnectApp.msg_21': '.',

  'entryPoint.reconnect.msg_21': 'Once your visit is complete, click ',
  'entryPoint.reconnect.msg_22': '.',

  'entryPoint.reconnect.reconnectButton': 'Reconnect To Visit',
  'entryPoint.reconnect.reconnectBrowserButton': 'Reconnect To Visit in Browser',
  'entryPoint.reconnect.reconnectAppButton': 'Reconnect To Visit in App',
  'entryPoint.reconnect.endButton': 'End Visit',


  'entryPoint.complete.title': 'Your Visit Has Ended',
  'entryPoint.complete.msg_1': 'Your virtual visit is complete, please close this tab.',
  'entryPoint.complete.msg_2': 'If you have any questions or concerns, please contact us at ',

  // 'entryPoint.finished.title': 'Your Appointment Has Started',
  // 'entryPoint.finished.info_1': 'Your appointment has been started in a new tab.  Please close the tabs when you are finished.',
  // 'entryPoint.finished.info_2': 'If you have been accidentally disconnected, click the',
  // 'entryPoint.finished.info_3': 'button.',
  // 'entryPoint.finished.reconnectButton': 'Reconnect To Your Appointment',
  // 'entryPoint.finished.startNewButton': 'Start a New Appointment',

  errorMessage: 'An error has occurred.',

  blank: ''
}
